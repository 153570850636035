<template>
	<div class="content p-0" style="background-color: #cbd8e1; height: 100vh">
		<div class="login-wrapper">
			<div class="row m-0">
				<div
					class="col-lg-6 h-100-vh side-banner"
					style="
						background-image: url('https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/e7f911620268d42205f453d67ad6c80c.jpg');
						background-position: center center;
						background-size: contain;
						display: flex;
						justify-content: center;
					"
				></div>
				<div class="col-lg-6 site-content">
					<div class="content" style="padding-top: 0px">
						<div class="row">
							<div class="col-lg-10 mx-auto">
								<div class="text-center mb-4">
									<h2>Activa tu cuenta</h2>
								</div>
								<div class="card shadow border-0">
									<div class="card-body text-center">
										<p>
											Ingresa el código de 6 digitos que enviamos a tu correo electrónico
										</p>
										<v-form action class="form" ref="form" v-model="valid">
											<v-text-field
												v-model="code"
												counter
												prepend-inner-icon="mdi-account-key"
												:rules="[rules.required, rules.length]"
												oninput="if(this.value.length > 6){this.value = this.value.slice(0,6);}"
												type="number"
												style="padding: 6px; text-align: center"
												label="Código de confirmación"
												maxlength="6"
											></v-text-field>
											<div class="text-center">
												<button
													type="submit"
													:disabled="!valid"
													@click.prevent="validate"
													class="btn btn-primary mt-2 mb-3"
													style="
														color: #fff;
														background-color: #009ada;
														border-color: #009ada;
													"
												>
													Activar
												</button>
												<p v-if="error" style="padding-top: 10px" class="error">
													Código inválido, intente de nuevo por favor.
												</p>
											</div>
										</v-form>
										<button
											title="Ir a la pagina principal"
											style="
												color: #fff;
												background-color: #009ada;
												border-color: #009ada;
											"
											@click.prevent="$router.push('/')"
											class="btn btn-primary"
										>
											<v-icon dark style="margin: 0px"> mdi-arrow-collapse-right </v-icon
											><v-icon dark style="margin: 0px"> mdi-home </v-icon>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	var urlSignupActivate = process.env.VUE_APP_API_DIRECTORY + 'auth/signup/activate/'
	export default {
		name: 'ActivateSection',
		data: () => ({
			code: '',
			error: false,
			valid: true,
			rules: {
				required: (value) => !!value || 'Requerido.',
				length: (v) => v.length == 6 || 'El código es de 6 caracteres',
				email: (value) => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'El formato de correo electrónico inválido'
				},
			},
		}),
		methods: {
			validate() {
				if (this.$refs.form.validate()) {
					this.active()
				}
			},
			active() {
				axios
					.get(urlSignupActivate + this.code)
					.then((response) => {
						this.$swal({
							title: `¡Hola!`,
							html: `Activaste tu cuenta satisfactoriamente.<br>Inicia sesión para disfrutar de nuestro Directorio de Franquicias`,
							showCancelButton: false,
							showConfirmButton: true,
							confirmButtonColor: '#003a56',
							confirmButtonText: 'Aceptar',
						})
						this.$router.push('/session/login')
					})
					.catch((error) => {
						console.log(error)
						this.error = true
					})
			},
		},
	}
</script>
<style lang="scss" scoped>
	.v-text-field__details {
		display: none;
		flex: 1 0 auto;
		max-width: 100%;
		min-height: 14px;
		overflow: hidden;
	}
	.col-lg-4,
	.col-md-6,
	.col-sm-12 {
		padding: 0px auto !important;
	}
	.theme--light.v-input {
		color: rgba(0, 0, 0, 0.87);
		padding: 0px;
		margin: 0px;
	}
	.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
		display: flex;
		flex: 1 1 auto;
		position: relative;
		width: 100%;
	}
</style>
